import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import FooterComponent from "../../../component/footer/FooterComponent";
import NavbarComponent from "../../../component/myadmin/navbar/Navbar.component";
import RentalManageNavbarComponent from "../../../component/myadmin/navbar/RentalManageNavbar.component";
import { useCustomRouterHook } from "../../../hooks/router/useCustomRouterHook";

export default function MyadminRentalManageLayout(props) {
    const userRdx = useSelector(state => state.userRedux);

    if (!userRdx.userInfo?.roomId) {
        return (
            <>
                <Outlet />
            </>
        );
    }
    return (
        <>
            <RentalManageNavbarComponent />
            <Outlet />
        </>
    );
}       