import React from 'react';
import MainComponent from '../component/home-v2';

const HomePage = (props) => {
    return (
        <React.Fragment>
            <MainComponent></MainComponent>
        </React.Fragment>
    );
}
export default HomePage;