// import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Suspense} from 'react';
import HomePage from './page/home';
import SearchOrderPage from './page/search/order';
import LoginPage from './page/login';
import SignupPage from './page/signup';
import NotFoundPage from './page/404';
import RoomPage from './page/room';
import MyadminPage from './page/myadmin';
import MyadminProductsPage from './page/myadmin/products';
import ProductPage from './page/product';
import CartPage from './page/cart';
import MyadminRentalManageNewOrderPage from './page/myadmin/rental-manage/new-order';
import MyadminRentalManageConfirmOrderPage from './page/myadmin/rental-manage/confirm-order';
import MyadminRentalManageConfirmReservationPage from './page/myadmin/rental-manage/confirm-reservation';
import MyadminRentalManagePickedUpPage from './page/myadmin/rental-manage/picked-up';
import MyadminRentalManageReturnedPage from './page/myadmin/rental-manage/returned';
import MyadminRentalManageCompletedPage from './page/myadmin/rental-manage/completed';
import MyadminRentalManageCancelledPage from './page/myadmin/rental-manage/cancelled';
import MyadminCreatePage from './page/myadmin/create';
import NavbarMain from './component/navbar/NavbarMain';
import PermissionComponent from './component/common/PermissionComponent';
import MyadminMainLayout from './page/myadmin/layout/MyadminMainLayout';
import MyadminRentalManageLayout from './page/myadmin/layout/MyadminRentalManageLayout';
import FooterComponent from './component/footer/FooterComponent';
import styled from 'styled-components';
import MyadminCustomerServicePage from './page/myadmin/customer-service';
import MyadminCustomerServiceSmsTemplatePage from './page/myadmin/customer-service/sms-template';
import IEAlert from 'component/common/IEAlert';

const Container = styled.div`
    min-height: 500px;
`;

/**
 * 
 * color
 * red : #e56767
 * green : #5fcf80
 * blue : #2c73d2
 * brown : #b39283
 */
function App() {

    return (
        <>
            <IEAlert />
            <PermissionComponent />
            <NavbarMain />
            <Container>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path='/' element={<HomePage />}></Route>
                        <Route path='/cart' element={<CartPage />}></Route>
                        <Route path='/search/order' element={<SearchOrderPage />}></Route>
                        <Route path='/login' element={<LoginPage />}></Route>
                        <Route path='/signup' element={<SignupPage />}></Route>
                        <Route path='/product' element={<ProductPage />}></Route>
                        <Route path='/room' element={<RoomPage />}></Route>
                        <Route path='/myadmin' element={<MyadminMainLayout />}>
                            <Route index element={<MyadminPage />}></Route>
                            <Route path='create' element={<MyadminCreatePage />}></Route>
                            <Route path='products' element={<MyadminProductsPage />}></Route>
                            <Route path='rental-manage' element={<MyadminRentalManageLayout />}>
                                <Route path='new-order' element={<MyadminRentalManageNewOrderPage />}></Route>
                                <Route path='confirm-order' element={<MyadminRentalManageConfirmOrderPage />}></Route>
                                <Route path='confirm-reservation' element={<MyadminRentalManageConfirmReservationPage />}></Route>
                                <Route path='picked-up' element={<MyadminRentalManagePickedUpPage />}></Route>
                                <Route path='returned' element={<MyadminRentalManageReturnedPage />}></Route>
                                <Route path='completed' element={<MyadminRentalManageCompletedPage />}></Route>
                                <Route path='cancelled' element={<MyadminRentalManageCancelledPage />}></Route>
                            </Route>
                            <Route path='customer-service' element={<MyadminCustomerServicePage />}></Route>
                            <Route path='customer-service/sms-template' element={<MyadminCustomerServiceSmsTemplatePage />}></Route>
                        </Route>
                        <Route path='/*' element={<NotFoundPage />}></Route>
                    </Routes>
                </Suspense>
            </Container>
            <FooterComponent></FooterComponent>
        </>
    );

}

export default App;
