import { useEffect } from "react";
import { useSelector } from "react-redux";
import FooterComponent from "../../../../component/footer/FooterComponent";
import MainComponent from "../../../../component/myadmin/rental-manage/confirm-order";
import NavbarMain from "../../../../component/navbar/NavbarMain";
import { useCustomRouterHook } from "../../../../hooks/router/useCustomRouterHook";

export default function MyadminRentalManageConfirmOrderPage(props) {
    const customRouter = useCustomRouterHook();
    const userRdx = useSelector(state => state.userRedux);

    useEffect(() => {
        if (userRdx.isLoading) {
            return;
        }

        if (!userRdx.userInfo) {
            customRouter.push({
                pathname: '/',
                replace: true
            })
            return;
        }

        if (!userRdx.userInfo.roomId) {
            customRouter.push({
                pathname: '/myadmin/create',
                replace: true
            })
            return;
        }

    }, [userRdx]);

    if (userRdx.userInfo && userRdx.userInfo.roomId) {
        return (
            <>
                <MainComponent />
            </>
        );
    }
    
    return null;
}