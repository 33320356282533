import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import FooterComponent from "../../../component/footer/FooterComponent";
import NavbarComponent from "../../../component/myadmin/navbar/Navbar.component";

export default function MyadminMainLayout(props) {
    const userRdx = useSelector(state => state.userRedux);


    if (!userRdx.userInfo?.roomId) {
        return (
            <>
                <Outlet />
            </>
        );
    }

    return (
        <>
            <NavbarComponent />
            <Outlet />
        </>
    );
}       