import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userDataConnect } from "../../data_connect/userDataConnect";
import { useCustomRouterHook } from "../../hooks/router/useCustomRouterHook";
import { v4 as uuidv4 } from 'uuid';

export default function PermissionComponent(props) {
    const customRouter = useCustomRouterHook();
    const reduxDispatch = useDispatch();

    useEffect(() => {
        reduxDispatch({
            type:'USER_REDUX_SET_IS_LOADING',
            payload:true
        })
        async function fetchUserRdx() {
            await __userRdx.req.fetchUserInfo();
        }
        fetchUserRdx();

    }, [customRouter.location]);

    const __userRdx = {
        req: {
            fetchUserInfo: async () => {
                await userDataConnect().searchUserInfo()
                    .then(res => {
                        if (res.status === 200) {
                            reduxDispatch({
                                type: 'USER_REDUX_SET_DATA',
                                payload: {
                                    userInfo: res.data.data,
                                    isLoading: false,
                                    status: 'fetched',
                                }
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err, err.response);
                        reduxDispatch({
                            type: 'USER_REDUX_SET_DATA',
                            payload: {
                                userInfo: null,
                                isLoading: false,
                                status: 'fetched'
                            }
                        });
                    })
            }
        }
    }
    return null;
}