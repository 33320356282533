import styled from 'styled-components';

export const Container = styled.div`
    flex:1;
    width:100%;
    padding:0 40px;

    @media all and (max-width:992px){
        padding:0;
    }

    .first-wrapper{
        margin-top: 0;

        @media all and (max-width:992px){
            margin-top: 20px;
        }
    }
`;

export const ContentWrapper = styled.div`
    margin-top: 20px;
    
    .room-box{
        display: flex;
        align-items: center;
        background:#00000008;
        border-radius: 5px;
        padding:10px
    }

    .room-box>.badge-figure{
        border: 1px solid #e0e0e0;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        overflow: hidden;
        background:#f0f0f080;

        @media all and (max-width:992px){
            width: 30px;
            height: 30px;
        }
    }

    .room-box>.badge-figure>.badge{
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .room-box>.nickname{
        margin-left: 10px;
        font-size: 18px;
        font-weight: 500;

        @media all and (max-width:992px){
            font-size: 13px;
        }
    }

    .product-title{
        font-size: 30px;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 20px;
        }
    }

    .rentalHour-box{
        display: flex;
        justify-content: flex-end;
        font-size: 18px;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 16px;
        }
    }

    .price-box{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    
    .price-box>div{
        font-size: 24px;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 18px;
        }
    }

    .price-box>div:nth-last-child(1){
        display: flex;
        justify-content: flex-end;
        width: 200px;
    }

    .price-box_24h{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    
    .price-box_24h>div{
        font-size: 16px;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .price-box_24h>div:nth-last-child(1){
        display: flex;
        justify-content: flex-end;
        width: 200px;
    }

    .region-list-box{

    }

    .region-list-box>.title{
        font-size: 18px;
        font-weight: 500;
        color: #505050;

        @media all and (max-width:992px){
            font-size: 15px;
        }
    }

    .region-list-box>.item-list{
        /* list-style: none; */
        margin-top: 5px;
        padding: 0 0 0 20px;
    
        /* max-width: 250px; */
        /* width: 100%; */
    }

    .region-list-box>.item-list>.item{
        /* margin-top: 5px; */
        line-height: 1.5;
        font-size: 16px;
        font-weight: 500;
        color: #505050;

        @media all and (max-width:992px){
            font-size: 13px;
        }
    }

    .service-number-box{

    }

    .service-number-box>.title{
        font-size: 18px;
        font-weight: 500;
        color: #505050;

        @media all and (max-width:992px){
            font-size: 15px;
        }
    }

    .service-number-box>.number{
        margin-top: 5px;
        line-height: 1.5;
        font-size: 16px;
        font-weight: 500;
        color: #505050;

        @media all and (max-width:992px){
            font-size: 13px;
        }
    }

    .description-box{
        white-space: pre-line;
        word-break: keep-all;
        font-size: 16px;
        line-height: 1.5;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .button-group{
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
    }

    .button-group>.contact-button{
        width: 300px;
        height: 48px;
        margin: 0;
        padding: 0;
        border: 1px solid #b39283;
        background: white;
        color: #b39283;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
        margin-right: 10px;

        @media all and (max-width:992px){
            flex:1;
            height: 38px;
            font-size: 15px;
        }
    }

    .button-group>.add-button{
        width: 300px;
        height: 48px;
        margin: 0;
        padding: 0;
        border: 1px solid #b39283;
        background: #b39283;
        color: white;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;

        @media all and (max-width:992px){
            flex:1;
            height: 38px;
            font-size: 15px;
        }
    }
`;

export const AddCartModalWrapper = styled.div`
    .product-title{
        padding: 10px;
        font-weight: 600;
    }

    .count-select-box{
        padding: 30px 10px;
    }

    .count-select-box>.label{
        color: #555;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .count-select-box>.item{
        width: 100%;
        margin:0;
        box-sizing: border-box;
    }

    .button-box{
        display: flex;
    }

    .button-box>.button-el{
        margin:0;
        border:none;
    }
`;

export const ContactModalWrapper = styled.div`
    .title{
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-indent: 0.08em;
        text-align: center;
        padding:20px;

        @media all and (max-width:992px){
            font-size: 16px;
        }
    }

    .phoneNumber-el{
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-indent: 0.08em;
        text-align: center;
        padding:20px;

        @media all and (max-width:992px){
            font-size: 16px;
        }
    }

    .button-group{
        margin-top: 20px;
        display: flex;
    }

    .button-el{
        margin:0;
        padding:0;
        height: 38px;
        border: none;
        font-weight: 600;
        letter-spacing: 0.1em;
        text-indent: 0.1em;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }
`;