import { GlobalCommonBodyContainer } from "../../../../../globalStyledComponent";
import styled from 'styled-components';
import { dateFormatUtils } from "../../../../../utils/dateFormatUtils";
import LineBreakerBottom from "../../../../module/fragment/LineBreakerBottom";
import SingleBlockButton from "../../../../module/button/SingleBlockButton";
import { numberFormatHandler } from "../../../../../utils/numberFormatHandler";
import { useState } from "react";
import CommonModalComponent from "../../../../module/modal/CommonModalComponent";
import { Container, InfoWrapper, ProductListWrapper, Wrapper, WriteSmsModalWrapper } from "./ProductListField.styled";
import { useEffect } from "react";
import _ from "lodash";
import { returnProductOrderPrice, returnProductsTotalPrice, returnRentalDiffHours, returnStatus, returnTransformedSmsText } from "./ProductListField.utils";
import { useCsSmsTemplate, useDisabledBtn, useWriteSms } from "../hooks/rentalOrderInfoHooks";

export default function ProductListFieldComponent(props) {
    const [writeSmsModalOpen, setWriteSmsModalOpen] = useState(false);

    const __handle = {
        action: {
            openWriteSmsModal: () => {
                setWriteSmsModalOpen(true);
            },
            closeWriteSmsModal: () => {
                setWriteSmsModalOpen(false);
            }
        },
        submit: {
            confirmSendSms: (message) => {
                let body = {
                    rentalOrderInfoId: props.selectedRentalOrderInfo.id,
                    smsMessage: message
                }

                if (window.confirm('알림 메세지를 해당 고객님의 전화번호로 전송합니다.')) {
                    props.onSubmitSendSms({
                        body: body,
                        callback: __handle.action.closeWriteSmsModal
                    })
                }
            }
        }
    }

    if (props.rentalOrderInfoPage && props.selectedRentalOrderInfo) {
        return (
            <>
                <Container>
                    <Wrapper>
                        <Info
                            selectedRentalOrderInfo={props.selectedRentalOrderInfo}
                            openWriteSmsModal={__handle.action.openWriteSmsModal}
                        />
                        <LineBreakerBottom
                            gapTop={20}
                        />
                        <ProductList
                            selectedRentalOrderInfo={props.selectedRentalOrderInfo}
                        />
                    </Wrapper>
                </Container>

                {writeSmsModalOpen &&
                    <CommonModalComponent
                        open={writeSmsModalOpen}
                        onClose={__handle.action.closeWriteSmsModal}
                    >
                        <WriteSmsModal
                            rentalOrderInfo={props.selectedRentalOrderInfo}
                            onSubmitConfirmSendSms={__handle.submit.confirmSendSms}
                        />
                    </CommonModalComponent>
                }
            </>
        );
    }

    return (
        <Container>
            <Wrapper>
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >주문 정보가 여기에 나타납니다.</div>
            </Wrapper>
        </Container>
    );
}

function Info({
    selectedRentalOrderInfo,
    openWriteSmsModal
}) {
    return (
        <InfoWrapper>
            <div className='info-group'>
                <div className='info-box'>
                    <div className='label'>
                        주문일시
                    </div>
                    <div className='info-el'>
                        <div className='info-el'>{dateFormatUtils().dateToYYMMDDHHmmss(selectedRentalOrderInfo.createdAt)}</div>
                    </div>
                </div>
                <div className='info-box'>
                    <div className='label'>
                        주문번호
                    </div>
                    <div className='info-el'>
                        {selectedRentalOrderInfo.orderNumber}
                    </div>
                </div>
            </div>
            <div className='info-group'>
                <div className='info-box'>
                    <div className='label'>
                        주문자 이름
                    </div>
                    <div className='info-el'>
                        <div className='info-el'>{selectedRentalOrderInfo.orderer}</div>
                    </div>
                </div>
                <div className='info-box'>
                    <div className='label'>
                        주문자 연락처
                    </div>
                    <div className='info-el'>
                        {selectedRentalOrderInfo.ordererPhoneNumber}
                    </div>
                </div>
            </div>
            <div className='info-group'>
                <div className="info-box">
                    <div className='label'>대여기간</div>
                    <div className='info-el'>
                        {dateFormatUtils().dateToYYMMDD(selectedRentalOrderInfo.pickupDate)} {selectedRentalOrderInfo.pickupTime} ~ {dateFormatUtils().dateToYYMMDD(selectedRentalOrderInfo.returnDate)} {selectedRentalOrderInfo.returnTime} ({returnRentalDiffHours({ pickupDate: selectedRentalOrderInfo.pickupDate, pickupTime: selectedRentalOrderInfo.pickupTime, returnDate: selectedRentalOrderInfo.returnDate, returnTime: selectedRentalOrderInfo.returnTime })}H)
                    </div>
                </div>
            </div>
            <div className='info-group'>
                <div className="info-box">
                    <div className='label'>픽업 | 반납 장소</div>
                    <div className='info-el'>{selectedRentalOrderInfo.returnPlace}</div>
                </div>
            </div>
            <div className='button-group'>
                <SingleBlockButton
                    type='button'
                    className='button-el'
                >
                    메모보기
                </SingleBlockButton>
                <SingleBlockButton
                    type='button'
                    className='button-el'
                    onClick={openWriteSmsModal}
                >
                    알림문자 작성
                </SingleBlockButton>
            </div>
        </InfoWrapper>
    );
}

function ProductList({
    selectedRentalOrderInfo
}) {
    return (
        <ProductListWrapper>
            <div className='total-price-box'>
                총 주문 가격 : {numberFormatHandler().numberWithCommas(returnProductsTotalPrice({ products: selectedRentalOrderInfo.rentalOrderProducts, diffHours: returnRentalDiffHours({ pickupDate: selectedRentalOrderInfo.pickupDate, pickupTime: selectedRentalOrderInfo.pickupTime, returnDate: selectedRentalOrderInfo.returnDate, returnTime: selectedRentalOrderInfo.returnTime }) }) || 0)} 원
            </div>

            {selectedRentalOrderInfo.rentalOrderProducts.map(r => {
                let diffHours = returnRentalDiffHours({ pickupDate: selectedRentalOrderInfo.pickupDate, pickupTime: selectedRentalOrderInfo.pickupTime, returnDate: selectedRentalOrderInfo.returnDate, returnTime: selectedRentalOrderInfo.returnTime });
                let productPrice = returnProductOrderPrice({ price: r.price, unit: r.unit, discountYn: r.discountYn, discountRate: r.discountRate, diffHours: diffHours, discountMinimumHour: r.discountMinimumHour });

                return (
                    <div
                        key={r.id}
                        className='product-box'
                    >
                        <div className='thumbnail-box'>
                            <div className='thumbnail-figure'>
                                <img
                                    className="thumbnail-el"
                                    src={r.thumbnailUri}
                                    alt='thumbnail'
                                ></img>
                            </div>
                        </div>
                        <div className='product-info-box'>
                            <div
                                className='status-badge'
                            >
                                {returnStatus({ type: r.status })}
                            </div>
                            <div className='title'>
                                {r.productName}
                            </div>
                            <div className='count'>
                                {numberFormatHandler().numberWithCommas(r.price || 0)} 원 / {r.unit}개 / {diffHours}H
                            </div>
                            {r.discountYn === 'y' && (diffHours >= r.discountMinimumHour) &&
                                <div className='discount'>{r.discountRate}% 할인 적용</div>
                            }
                            <div className='totalPrice'>
                                상품 최종 가격: {numberFormatHandler().numberWithCommas(productPrice || 0)} 원
                            </div>
                        </div>
                    </div>
                );
            })}
        </ProductListWrapper>
    );
}

function WriteSmsModal({
    rentalOrderInfo,
    onSubmitConfirmSendSms
}) {
    const {
        writeText,
        defaultText,
        viewText,
        onChangeWriteText,
        onSetWriteText,
        onSetDefaultText,
        onSetViewText
    } = useWriteSms();

    const {
        csSmsTemplates
    } = useCsSmsTemplate();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const [selectTemplateModeOpen, setSelectTemplateModeOpen] = useState(false);

    useEffect(() => {
        let smsText = '[Campal | 캠핑렌탈]\n* 발신 전용 메세지 입니다.\n\n';
        smsText += `---info---\n`;
        smsText += `대여처: ${rentalOrderInfo.room.name}\n`;
        smsText += `대여처 연락처: ${rentalOrderInfo.room.phoneNumber}\n`;
        smsText += `----------\n\n`;

        onSetDefaultText(smsText)
    }, []);

    useEffect(() => {
        let targetSmsText = _.cloneDeep(writeText);

        targetSmsText = returnTransformedSmsText({
            smsText: targetSmsText,
            rentalOrderInfo: rentalOrderInfo
        });

        if (targetSmsText.length > 1000) {
            targetSmsText = targetSmsText.substring(0, 1000);
        }

        onSetViewText(targetSmsText);
    }, [writeText]);

    const __handle = {
        action: {
            openSelectTemplateMode: () => {
                setSelectTemplateModeOpen(true);
            },
            closeSelectTemplateMode: () => {
                setSelectTemplateModeOpen(false);
            },
            selectTemplate: (template) => {
                let text = _.cloneDeep(template.message);
                onSetWriteText(text);
                __handle.action.closeSelectTemplateMode();
            }
        },
        submit: {
            confirm: () => {
                if(disabledBtn){
                    return;
                }

                setDisabledBtn(true);

                let resultViewText = _.cloneDeep(viewText);

                
                if (resultViewText > 1000) {
                    resultViewText = resultViewText.substring(0, 1000);
                }

                let fullMessage = defaultText + resultViewText;

                onSubmitConfirmSendSms(fullMessage);
            }
        }
    }

    if (selectTemplateModeOpen) {
        return (
            <>
                <WriteSmsModalWrapper>
                    <div
                        className="header-box"
                        style={{
                            justifyContent: 'flex-start'
                        }}
                    >
                        <SingleBlockButton
                            type='button'
                            className='call-template-button-el'
                            onClick={__handle.action.closeSelectTemplateMode}
                        >
                            이전
                        </SingleBlockButton>
                    </div>
                    <div className='template-list-wrapper'>
                        {csSmsTemplates?.map(r => {
                            return (
                                <div
                                    key={r.id}
                                    className='template-card-el'
                                    onClick={() => __handle.action.selectTemplate(r)}
                                >
                                    <div className="name">
                                        {r.name}
                                    </div>
                                    <div className='message'>
                                        <div>{r.message}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </WriteSmsModalWrapper>
            </>
        );
    }

    return (
        <>
            <WriteSmsModalWrapper>
                <div className='header-box'>
                    <SingleBlockButton
                        type='button'
                        className='call-template-button-el'
                        onClick={__handle.action.openSelectTemplateMode}
                    >
                        템플릿 불러오기
                    </SingleBlockButton>
                </div>
                <div className='write-field-box'>
                    <div className='label'>[메세지 작성]</div>
                    <textarea
                        className='write-field-el'
                        value={writeText || ''}
                        onChange={onChangeWriteText}
                    ></textarea>
                </div>
                <div className='view-field-box'>
                    <div className='label'>[메세지 결과] ( {viewText.length} / 1000 )</div>
                    {viewText.length >= 1000 &&
                        <div className='notice'>* 메세지는 결과 기준 최대 1000자 까지만 전송 됩니다.</div>
                    }
                    <div className='view-field-el'>
                        {defaultText}{viewText}
                    </div>
                </div>
                <div className='send-sms-button-box'>
                    <SingleBlockButton
                        className='send-sms-button-el'
                        onClick={__handle.submit.confirm}
                        disabled={disabledBtn}
                    >
                        알림문자전송
                    </SingleBlockButton>
                </div>
            </WriteSmsModalWrapper>
        </>
    );
}