import { csSmsTemplateDataConnect } from "data_connect/csSmsTemplateDataConnect";
import { rentalOrderInfoDataConnect } from "data_connect/rentalOrderInfoDataConnect";
import { useCustomRouterHook } from "hooks/router/useCustomRouterHook";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useDisabledBtn = () => {
    const [disabledBtn, setDisabledBtn] = useState(false);

    useEffect(() => {
        if (!disabledBtn) {
            return;
        }

        let timeout = setTimeout(() => {
            setDisabledBtn(false);
        }, 1000)

        return () => clearTimeout(timeout);
    }, [disabledBtn])

    return [disabledBtn, setDisabledBtn];
}
export const useRentalOrderInfo = () => {
    const customRouter = useCustomRouterHook();
    const userRdx = useSelector(state => state.userRedux);
    const [rentalOrderInfoPage, setRentalOrderInfoPage] = useState(null);


    useEffect(() => {
        if (!customRouter.isReady) {
            return;
        }

        if (userRdx.isLoading) {
            return;
        }

        if (!userRdx.userInfo) {
            return;
        }

        reqFetch();
    }, [customRouter.isReady, userRdx.isLoading, userRdx.userInfo]);

    const reqFetch = async () => {
        let params = {
            size: customRouter.query.size || 20,
            page: customRouter.query.page || 1
        }
        await rentalOrderInfoDataConnect().searchPage({
            params: params
        })
            .then(res => {
                if (res.status === 200) {
                    setRentalOrderInfoPage(res.data.data)
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    const reqSendSms = async ({ body, callback }) => {
        await rentalOrderInfoDataConnect().sendSms({ body })
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                let res = err.response;

                if (!res) {
                    alert('네트워크 연결이 원활하지 않습니다.');
                    return;
                }

                if (res.status === 500) {
                    alert('undefined error.');
                    return;
                }

                alert(res.data.memo);
            })
        callback();
    }

    return {
        rentalOrderInfoPage,
        reqSendSms
    }
}

export const useSelectedRentalOrderInfo = () => {
    const [selectedRentalOrderInfo, setSelectedRentalOrderInfo] = useState(null);

    const onSelect = (nextState) => {
        if (selectedRentalOrderInfo && selectedRentalOrderInfo.id === nextState.id) {
            setSelectedRentalOrderInfo(null);
            return;
        }

        setSelectedRentalOrderInfo(nextState);
    }

    return {
        selectedRentalOrderInfo,
        onSelect
    }
}

export const useWriteSms = () => {
    const [writeText, setWriteText] = useState('');
    const [defaultText, setDefaultText] = useState('');
    const [viewText, setViewText] = useState('');

    const onSetWriteText = (state) => {
        setWriteText(state);
    }

    const onChangeWriteText = (e) => {
        let value = e.target.value;

        setWriteText(value);
    }

    const onSetDefaultText = (state) => {
        setDefaultText(state);
    }

    const onSetViewText = (state) => {
        setViewText(state);
    }

    return {
        writeText,
        defaultText,
        viewText,
        onChangeWriteText,
        onSetWriteText,
        onSetDefaultText,
        onSetViewText
    }
}

export const useCsSmsTemplate = () => {
    const [csSmsTemplates, setCsSmsTemplates] = useState(null);

    useEffect(() => {
        onFetch();
    }, [])

    const onFetch = async () => {
        await csSmsTemplateDataConnect().searchList()
            .then(res => {
                if (res.status === 200) {
                    setCsSmsTemplates(res.data.data);
                }
            })
            .catch(err => {
                console.log(err, err.response);
            })
    }

    return {
        csSmsTemplates,
        onFetch
    }
}