import styled from 'styled-components';

export const Container = styled.div`
    flex:1;
`;

export const Wrapper = styled.div`
    overflow: auto;
    position:relative;
    border:1px solid #e0e0e0;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 20px;
    height: 500px;

    &::-webkit-scrollbar{
        background: #e1e1e130;
        height:5px;
        width: 5px;
    }
    
    &::-webkit-scrollbar-track{
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb{
        background-color: #00000030;
        border-radius: 10px;
    }
`;

export const InfoWrapper = styled.div`
    &>.info-group:nth-child(1){
        margin-top: 0;
    }

    .info-group{
        display: flex;
        margin-top: 20px;
    }

    .info-box{
        flex:1
    }

    .info-box>.label{
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 11px;
        }
    }

    .info-box>.info-el{
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.1em;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .button-group{
        display: flex;
        justify-content: flex-end;
        margin-top:20px;

        &>.button-el:nth-last-child(1){
            border-right: 1px solid #e0e0e0;
        }
    }

    .button-group>.button-el{
        margin:0;
        width:110px;
        letter-spacing: 0.1em;
        text-indent: 0.1em;
        border-right: none;
        font-size: 14px;

        @media all and (max-width:992px){
            width:100px;
            font-size: 12px;
        }
    }
`;

export const ProductListWrapper = styled.div`
    .total-price-box{
        margin-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.1em;
        color:#b39283;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .product-box{
        display: flex;
        padding: 10px 0;
    }

    .thumbnail-box{
        overflow: hidden;
        width: 100px;

        @media all and (max-width:992px){
            width: 80px;
        }
    }

    .thumbnail-figure{
        position:relative;
        padding-bottom: 100%;
    }

    .thumbnail-el{
        position: absolute;
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .product-info-box{
        flex:1;
        margin-left: 10px;
    }

    .product-info-box>.status-badge{
        width:70px;
        padding:3px 0;
        border:1px solid #e0e0e0;
        border-radius: 5px;
        text-align: center;
        font-size: 12px;
        letter-spacing: 0.1em;
        text-indent: 0.1em;
        color: #303030;

        @media all and (max-width:992px){
            width:60px;
            font-size: 11px;
        }
    }

    .product-info-box>.title{
        margin-top: 5px;
        font-size: 16px;
        font-weight: 600;
        color:#404040;
        letter-spacing: 0.1em;

        @media all and (max-width:992px){
            font-size: 13px;
        }
    }

    .product-info-box>.count{
        font-size: 14px;
        font-weight: 500;
        color:#404040;
        letter-spacing: 0.1em;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .product-info-box>.discount{
        font-size: 14px;
        font-weight: 500;
        color:#e56767;
        letter-spacing: 0.1em;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .product-info-box>.totalPrice{
        font-size: 14px;
        font-weight: 500;
        color:#404040;
        letter-spacing: 0.1em;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }
`;

export const WriteSmsModalWrapper = styled.div`
    position:relative;

    .header-box{
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        padding: 0 20px;
    }

    .call-template-button-el{
        margin:0;
        width:150px;
        font-size: 14px;

        @media all and (max-width:992px){
            width:100px;
            font-size: 12px;
        }
    }

    .write-field-box{
        padding:20px;
        border-bottom: 1px solid #e0e0e0;
    }

    .write-field-box>.label{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .write-field-box>.write-field-el{
        width:100%;
        box-sizing: border-box;
        border-radius: 5px;
        resize: none;
        height: 150px;
        padding:10px;
        letter-spacing: 0.07em;
        font-size: 14px;
        outline: none;
        border: 1px solid #e0e0e0;

        &::-webkit-scrollbar{
            background: #e1e1e130;
            height:5px;
            width: 5px;
        }
        
        &::-webkit-scrollbar-track{
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb{
            background-color: #00000030;
            border-radius: 10px;
        }

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .view-field-box{
        padding:20px;
        
    }

    .view-field-box>.label{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
        color:#404040;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .view-field-box>.notice{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        color:red;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .view-field-box>.view-field-el{
        white-space: pre-line;
        font-family: monospace;
        font-size: 14px;
        letter-spacing: 0.07em;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .send-sms-button-box{
        position:sticky;
        bottom:0;
    }

    .send-sms-button-el{
        margin:0;
        border: none;
        background:#b39283;
        color:white;
        letter-spacing: 0.08em;
        text-indent: 0.08em;
        font-weight: 600;
        font-size: 16px;

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .template-list-wrapper{
        padding:20px;
    }

    .template-card-el{
        padding:10px;
        border:1px solid #e0e0e0;
        border-radius: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        -webkit-tap-highlight-color: #00000000;
    }

    .template-card-el>.name{
        font-weight: 600;
        letter-spacing: 0.08em;
        font-size: 16px;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .template-card-el>.message{
        margin-top: 5px;
        letter-spacing: 0.08em;
        white-space: pre-line;
        font-size: 10px;
        /* 글자가 라인 두줄 범위 넘어가면 ...처리 */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        /* 글자가 라인 두줄 범위 넘어가면 ...처리 */
    }
`;