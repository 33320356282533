import { useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import { roomDataConnect } from '../../../../data_connect/roomDataConnect';
import { validationDataConnect } from '../../../../data_connect/validationDataConnect';
import { checkPhoneNumberFormat } from '../../../../utils/regexUtils';
import SingleBlockButton from '../../../module/button/SingleBlockButton';

const Container = styled.div`
    margin-top: 70px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;

    @media all and (max-width:992px){
    }
`;

const FormWrapper = styled.form`
    position:relative;
    flex:1;
    border:1px solid #e0e0e0;
    border-radius: 10px;
    padding:20px;

    &>.form-box:nth-child(1){
        margin-top: 0;
    }

    .form-box{
        width:100%;
        margin-top: 30px;
    }
    
    .form-box>.input-el{
        width:100%;
        padding:10px;
        box-sizing: border-box;
        border:none;
        letter-spacing: 0.1em;
        border-bottom: 1px solid #e0e0e0;
        outline: none;
        font-size: 16px;

        &:focus{
            border-bottom: 1px solid #b39283;
        }

        @media all and (max-width:992px){
            font-size: 14px;
        }
    }

    .form-box>.label{
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
        margin-bottom: 5px;

        @media all and (max-width:992px){
            font-size: 12px;
        }
    }

    .form-box>.input-notice{
        color: #707070;
        font-size: 12px;
        margin-top: 3px;
        letter-spacing: 0.08em;

        @media all and (max-width: 992px){
            font-size: 10px;
        }
    }

    .search-button-el{
        margin:0;
        padding:0;
        width:200px;
        height: 43px;
        letter-spacing: 0.1em;
        background:#000;
        font-size: 16px;
        color:white;
        font-weight: 600;

        @media all and (max-width:992px){
            font-size: 14px;
            width:100%;
        }
    }
`;

export default function FormFieldComponent(props) {
    const [formValue, dispatchFormValue] = useReducer(formValueReducer, initialFormValue);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [activePhoneNumberValidationCodeInput, setActivePhoneNumberValidationCodeInput] = useState('');

    useEffect(() => {
        if (!disabledBtn) {
            return;
        }

        let timeout = setTimeout(() => {
            setDisabledBtn(false);
        }, 1000)

        return () => clearTimeout(timeout);
    }, [disabledBtn])

    const __formValue = {
        change: {
            valueOfName: (e) => {
                let name = e.target.name;
                let value = e.target.value;

                dispatchFormValue({
                    type: 'SET_DATA',
                    payload: {
                        ...formValue,
                        [name]: value
                    }
                })
            }
        },
        submit: {
            confirmCreate: (e) => {
                e.preventDefault();

                if (!formValue.name || formValue.name.search(/^[\s]|[\s]$/g) !== -1 || formValue.name.length < 2 || formValue.name > 15) {
                    alert('룸 이름을 정확하게 입력해 주세요. 2-15 글자 내로 첫 글자와 마지막 글자에 공백은 허용되지 않습니다.');
                    return;
                }

                if (!formValue.phoneNumber || !checkPhoneNumberFormat(formValue.phoneNumber)) {
                    alert('휴대폰 번호를 형식에 맞게 입력해 주세요.');
                    return;
                }

                if (!activePhoneNumberValidationCodeInput) {
                    alert('휴대폰 인증을 진행해 주세요.');
                    return;
                }

                if (!formValue.phoneValidationCode || formValue.phoneValidationCode.length !== 6) {
                    alert('인증번호를 정확하게 입력해 주세요.');
                    return;
                }

                props.onSubmitCreateRoom({ body: formValue });
            }
        }
    }

    const __formValid = {
        req: {
            sendPhoneNumberValidationCode: async () => {
                let phoneNumber = formValue.phoneNumber;

                await roomDataConnect().sendPhoneValidationCode({ phoneNumber })
                    .then(res => {
                        if (res.status === 200) {
                            alert('해당 휴대폰 번호로 인증번호를 발송했습니다.');
                            setActivePhoneNumberValidationCodeInput(true);
                        }
                    })
                    .catch(err => {
                        let res = err.response;
                        if (res.status === 500) {
                            alert('undefined error.')
                            return;
                        }

                        alert(res.data.memo);
                    })
            }
        },
        action: {
            checkPhoneNumber: () => {
                let phoneNumber = formValue.phoneNumber;
                let bool = false;

                if (checkPhoneNumberFormat(phoneNumber)) {
                    bool = true;
                } else {
                    bool = false;
                }

                return bool;
            }
        },
        submit: {
            sendPhoneValidationCode: () => {
                setDisabledBtn(true);

                if (disabledBtn) {
                    return;
                }

                if (!checkPhoneNumberFormat(formValue.phoneNumber)) {
                    alert('휴대폰 번호를 형식에 맞게 입력해 주세요.');
                    return;
                }

                __formValid.req.sendPhoneNumberValidationCode();
            }
        }
    }
    return (
        <>
            <Container>
                <FormWrapper onSubmit={__formValue.submit.confirmCreate}>
                    <div className='form-box'>
                        <div className='label'>
                            룸 이름
                        </div>
                        <input
                            className='input-el'
                            type='text'
                            name={'name'}
                            value={formValue.name || ''}
                            onChange={__formValue.change.valueOfName}
                            placeholder="룸 이름을 입력해 주세요. (2-15 글자)"
                            required
                        ></input>
                    </div>
                    <div className='form-box'>
                        <div className='label'>
                            휴대폰 번호
                        </div>
                        <input
                            className='input-el'
                            type='text'
                            name={'phoneNumber'}
                            value={formValue.phoneNumber || ''}
                            onChange={__formValue.change.valueOfName}
                            placeholder="연락 받으실 휴대폰 번호를 입력해 주세요. ex) 01012341234"
                            required
                        ></input>
                    </div>
                    {activePhoneNumberValidationCodeInput &&
                        <>
                            <div className='form-box'>
                                <div className='label'>
                                    인증번호
                                </div>
                                <input
                                    className='input-el'
                                    type='text'
                                    name={'phoneValidationCode'}
                                    value={formValue.phoneValidationCode || ''}
                                    onChange={__formValue.change.valueOfName}
                                    placeholder="인증번호 6자리를 입력해 주세요."
                                    required
                                ></input>
                                <div className='input-notice'>인증번호를 발송했습니다.(유효시간 30분)</div>
                                <div className='input-notice'>인증번호가 오지 않으면 입력하신 정보가 정확한지 확인하여 주세요.</div>
                                <div className='input-notice' style={{ color: 'red' }}>이미 사용중인 휴대폰 번호는 인증번호를 받을 수 없습니다.</div>
                                <div className='input-notice' style={{ color: 'red' }}>인증번호를 여전히 받지 못한 경우 스팸 메세지함을 확인하여 주세요.</div>
                            </div>
                        </>
                    }
                    <div>
                        <SingleBlockButton
                            type='button'
                            onClick={__formValid.submit.sendPhoneValidationCode}
                        >

                            {activePhoneNumberValidationCodeInput ?
                                '인증번호 재발송' : '인증번호 발송'
                            }
                        </SingleBlockButton>
                    </div>
                    <div
                        className='form-box'
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <SingleBlockButton
                            className='search-button-el'
                            type='submit'
                        >
                            룸 생성하기
                        </SingleBlockButton>
                    </div>
                </FormWrapper>
            </Container>
        </>
    );
}

const initialFormValue = {
    name: '',
    phoneNumber: '',
    phoneValidationCode: ''
}

const formValueReducer = (state, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return action.payload;
        case 'CLEAR':
            return initialFormValue;
        default: return initialFormValue;
    }
}