import styled from 'styled-components';
import { useCustomRouterHook } from '../../../hooks/router/useCustomRouterHook';
import CustomSelect from '../../module/select/CustomSelect';
import { CategorySelectWrapper, Container, OrderTypeWrapper, Wrapper } from './CategoryField.styled';

export default function CategoryFieldComponent(props) {
    const customRouter = useCustomRouterHook();

    const __handle = {
        action: {
            changeRoute: (e) => {
                let value = e.target.value;
                delete customRouter.query.page;

                if (!value) {
                    delete customRouter.query.categoryId;

                    customRouter.push({
                        pathname: customRouter.pathname,
                        query: {
                            ...customRouter.query
                        },
                        replace: true
                    });
                    return;
                }

                customRouter.push({
                    pathname: customRouter.pathname,
                    query: {
                        ...customRouter.query,
                        categoryId: value
                    },
                    replace: true
                })
            },
            changeRouteByOrderType: (type) => {
                if (customRouter.query.orderType === type) {
                    return;
                }
                delete customRouter.query.page;

                customRouter.push({
                    pathname: customRouter.pathname,
                    query: {
                        ...customRouter.query,
                        orderType: type
                    },
                    replace: true
                });

            }
        }
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <CategorySelectWrapper>
                        <CustomSelect
                            className='select-el'
                            value={customRouter.query.categoryId || ''}
                            onChange={__handle.action.changeRoute}
                        >
                            <option value=''>카테고리 전체</option>
                            {props.categories.map(r => {
                                return (
                                    <option
                                        key={r.id}
                                        value={r.id}
                                    >
                                        {r.name}
                                    </option>
                                );
                            })}
                        </CustomSelect>
                    </CategorySelectWrapper>
                    <OrderTypeWrapper>
                        <div
                            className={`item-el ${!customRouter.query.orderType || customRouter.query.orderType === 'order_rank' ? 'item-el-active' : ''}`}
                            onClick={() => __handle.action.changeRouteByOrderType('order_rank')}
                        >
                            랭킹순
                        </div>
                        <div
                            className={`item-el ${customRouter.query.orderType === 'order_new' ? 'item-el-active' : ''}`}
                            onClick={() => __handle.action.changeRouteByOrderType('order_new')}
                        >
                            최신순
                        </div>
                    </OrderTypeWrapper>
                </Wrapper>
            </Container>
        </>
    );
}