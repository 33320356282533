import CustomerListFieldComponent from "./customer-list-field/CustomerListField.component";
import { useRentalOrderInfo, useSelectedRentalOrderInfo } from "./hooks/rentalOrderInfoHooks";
import MainLayout from "./layout/MainLayout";
import ProductListFieldComponent from "./product-list-field/ProductListField.component";
import TopControlFieldComponent from "./top-control-field/TopControlField.component";

export default function MainComponent(props) {
    const {
        rentalOrderInfoPage,
        reqSendSms
    } = useRentalOrderInfo();

    const {
        selectedRentalOrderInfo,
        onSelect
    } = useSelectedRentalOrderInfo();

    return (
        <>
            <TopControlFieldComponent />
            <MainLayout>
                <CustomerListFieldComponent
                    rentalOrderInfoPage={rentalOrderInfoPage}
                    selectedRentalOrderInfo={selectedRentalOrderInfo}

                    onActionSelectRentalOrderInfo={onSelect}
                />
                <div style={{ padding: '20px' }}></div>
                <ProductListFieldComponent
                    rentalOrderInfoPage={rentalOrderInfoPage}
                    selectedRentalOrderInfo={selectedRentalOrderInfo}

                    onSubmitSendSms={reqSendSms}
                />
            </MainLayout>
        </>
    );
}