import SingleBlockButton from "../../module/button/SingleBlockButton";
import styled from 'styled-components';
import { useState } from "react";
import FormFieldComponent from "./form-field/FormField.component";
import { roomDataConnect } from "../../../data_connect/roomDataConnect";
import { useDispatch, useSelector } from "react-redux";
import { useCustomRouterHook } from "../../../hooks/router/useCustomRouterHook";

const Container = styled.div`
    margin-bottom: 150px;
`;
export default function MainComponent(props) {
    const reduxDispatch = useDispatch();
    const [formModeOpen, setFormModeOpen] = useState(false);
    const customRouter = useCustomRouterHook();

    const __handle = {
        action: {
            openFormMode: () => {
                setFormModeOpen(true);
            }
        }
    }

    const __room = {
        req: {
            create: async ({ body }) => {
                await roomDataConnect().createOne({ body })
                    .then(res => {
                        if (res.status === 200) {

                            reduxDispatch({
                                type: 'USER_REDUX_SET_IS_LOADING',
                                payload: true
                            })
                            customRouter.push({
                                pathname: '/myadmin',
                                replace: true
                            })
                        }
                    })
                    .catch(err => {
                        let res = err.response;

                        if (!res) {
                            alert('네트워크 연결이 원활하지 않습니다.');
                            return;
                        }

                        if (res.status === 500) {
                            alert('undefined error.');
                            return;
                        }

                        alert(res.data.memo);
                    })
            }
        },
        submit: {
            create: async ({ body }) => {
                await __room.req.create({ body });
            }
        }
    }

    if (!formModeOpen) {
        return (
            <>
                <Container>
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: '20px',
                            letterSpacing: '0.1em',
                            fontWeight: '600',
                            lineHeight: '2',
                            padding: '100px 0',
                            color: '#404040'
                        }}
                    >
                        <div>
                            마이룸을 생성하고 자신의 제품을
                        </div>
                        <div>
                            다른 사람들에게 소개해 보세요.
                        </div>
                        <div>
                            <SingleBlockButton
                                type='button'
                                style={{
                                    margin: '20px auto',
                                    padding: '0',
                                    width: '250px',
                                    height: '43px',
                                    color: '#fff',
                                    fontWeight: '600',
                                    fontSize: '16px',
                                    background: '#000',
                                    border: 'none'
                                }}
                                onClick={__handle.action.openFormMode}
                            >
                                마이룸 생성 시작하기
                            </SingleBlockButton>
                        </div>
                    </div>
                </Container>
            </>
        );
    }

    if (formModeOpen) {
        return (
            <>
                <Container>
                    <FormFieldComponent
                        onSubmitCreateRoom={__room.submit.create}
                    />
                </Container>
            </>
        );
    }
}