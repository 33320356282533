import { GlobalCommonBodyContainer } from 'globalStyledComponent';
import SingleBlockButton from 'component/module/button/SingleBlockButton';
import { AddTemplateModalWrapper, Header, Wrapper } from './TopControlField.styled';
import CommonModalComponent from '../../../../module/modal/CommonModalComponent';
import { useCreateTemplate, useCustomModalOpen, useDisabledBtn } from '../hooks/smsTemplateHooks';
import CustomInput from '../../../../module/form/CustomInput';

export default function TopControlFieldComponent(props) {
    const {
        open: addTemplateModalOpen,
        openModal: onActionOpenAddTemplateModal,
        closeModal: onActionCloseAddTemplateModal
    } = useCustomModalOpen();

    const __handle = {
        submit: {
            addTemplate: (templateName) => {
                let body = {
                    name: templateName
                }

                props.onSubmitAddTemplate({
                    body: body,
                    callback: onActionCloseAddTemplateModal
                })
            }
        }
    }
    return (
        <>
            <GlobalCommonBodyContainer>
                <Header>
                    <div className='title-el'>
                        알림문자 템플릿
                    </div>
                </Header>
                <Wrapper>
                    <SingleBlockButton
                        type='button'
                        className='link-button-el'
                        onClick={() => onActionOpenAddTemplateModal()}
                    >
                        템플릿 생성
                    </SingleBlockButton>
                </Wrapper>
            </GlobalCommonBodyContainer>

            <CommonModalComponent
                open={addTemplateModalOpen}
                onClose={onActionCloseAddTemplateModal}
            >
                <AddTemplateModal
                    onSubmitConfirm={__handle.submit.addTemplate}
                    onClose={onActionCloseAddTemplateModal}
                />
            </CommonModalComponent>
        </>
    );
}

function AddTemplateModal({
    onSubmitConfirm,
    onClose
}) {
    const {
        createTemplate,
        onChangeValueOfName
    } = useCreateTemplate();

    const [disabledBtn, setDisabledBtn] = useDisabledBtn();

    const __handle = {
        submit: {
            confirm: (e) => {
                e.preventDefault();
                setDisabledBtn(true);

                if (createTemplate.name > 20) {
                    alert('템플릿 이름은 20자 이내로 입력해 주세요.');
                    return;
                }

                onSubmitConfirm(createTemplate.name);
            }
        }
    }

    return (
        <AddTemplateModalWrapper>
            <form
                className='form-box'
                onSubmit={__handle.submit.confirm}
            >
                <div className='input-box'>
                    <CustomInput
                        label={'템플릿 이름'}
                        name={'name'}
                        value={createTemplate.name || ''}
                        onChange={onChangeValueOfName}
                    />
                </div>
                <div className='button-box'>
                    <SingleBlockButton
                        className='button-el'
                        type='button'
                        onClick={onClose}
                    >
                        취소
                    </SingleBlockButton>
                    <SingleBlockButton
                        className='button-el'
                        type='submit'
                        style={{
                            color: '#b39283',
                            fontWeight: 600
                        }}
                        disabled={disabledBtn}
                    >
                        생성
                    </SingleBlockButton>
                </div>
            </form>
        </AddTemplateModalWrapper>
    );
}