import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    .link-button-el{
        user-select: none;
        color:#505050;
        width:150px;
        height: 34px;
        margin:0;
        letter-spacing: 0.1em;
        border-radius: 5px;
        font-size: 14px;

        @media all and (max-width: 992px){
            font-size: 12px;
            width:100px;
        }
    }

`;